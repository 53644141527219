<template>
  <div class="modal is-large is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered">Características</p>
        <button class="delete" aria-label="close" type="reset" @click="close()"></button>
      </header>
      <section class="modal-card-body">
        <div class="columns is-multiline">
          <div class="column is-12">
            <p>Selecciona las caracteristicas que mejor describan al producto</p>
          </div>
          <template v-for="(characteristics, index) of catalogCharacteristicsList">
            <div class="column is-3" :key="index" v-if="allowedIds.includes(characteristics.id)">
              <label class="checkbox" :class="{ 'has-text-danger': isError }">
                <input
                  type="checkbox"
                  @click="addCharacteristic(characteristics)"
                  :checked="characteristicsSelected.find(option => option.id === characteristics.id)"
                >
                {{characteristics.name}}
              </label>
            </div>
          </template>
        </div>
      </section>
      <footer class="modal-card-foot">
        <div class="field is-grouped">
            <div class="control">
              <button class="button is-link is-light" @click="close()">Cancelar</button>
            </div>
            <div class="control">
              <button class="button button-givu" @click="onSaveCharacteristic()">Guardar</button>
            </div>
          </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    close: {
      type: Function
    },
    catalogCharacteristicsList: {
      type: Array
    },
    characteristicsSelected: {
      type: Array
    },
    saveCharacteristic: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      allowedIds: [3, 1],
      characteristicsSelectedTemporally: [],
      isError: false
    }
  },
  mounted () {
    this.characteristicsSelectedTemporally = this.characteristicsSelected
  },
  methods: {
    addCharacteristic (characteristics) {
      let isExist = this.characteristicsSelectedTemporally.find(item => item.id === characteristics.id)
      if (isExist) {
        let removeCategory = this.characteristicsSelectedTemporally.filter(item => item.id !== characteristics.id)
        this.characteristicsSelectedTemporally = removeCategory
      } else {
        this.characteristicsSelectedTemporally = [ ...this.characteristicsSelectedTemporally, { ...characteristics, 'value': '' } ]
      }
      this.isError = false
    },
    async onSaveCharacteristic () {
      if (this.characteristicsSelectedTemporally.length) {
        this.saveCharacteristic(this.characteristicsSelectedTemporally)
      } else {
        this.isError = true
      }
    }
  }
}
</script>
